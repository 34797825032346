import Aes256 from './aes256';

const HEADER = '$ANSIBLE_VAULT;1.1;AES256';

class Ansible {
    static hex2a(hex) {
        let str = '';
        for (let i = 0; i < hex.length; i += 2) str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
        return str;
    }

    static a2hex(str) {
        let hex = '';
        for (let i = 0; i < str.length; i++) {
            const code = str.charCodeAt(i);
            hex += code.toString(16).padStart(2, '0');
        }
        return hex;
    }

    static decrypt(encrypted, password) {
        let encryptedArray = encrypted.split('\n');
        if (encryptedArray[0].trim() !== HEADER) {
            throw new Error('Incorrect input');
        }

        encryptedArray.shift();
        const encryptedString = this.hex2a(encryptedArray.map(element => element.trim()).join(''));
        return Aes256.decrypt(encryptedString, password);
    }

    static encrypt(unencrypted, password) {
        const encrypted = this.a2hex(Aes256.encrypt(unencrypted, password));
        const result = [];
        result.push(HEADER);
        const lines = Math.floor(encrypted.length / 80);
        for(let i = 0; i < lines; i++) {
            result.push(encrypted.substring(i * 80, (i + 1) * 80));
        }

        result.push(encrypted.substring(lines * 80, encrypted.length));
        return result.join('\n');
    }
}

export default Ansible;