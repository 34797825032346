const sjcl = require('sjcl');

sjcl.mode.ctr = {
    /** The name of the mode.
     * @constant
     */
    name: "ctr",
    /** Encrypt in CTR mode.
     * @param {Object} prf The pseudorandom function.  It must have a block size of 16 bytes.
     * @param {bitArray} plaintext The plaintext data.
     * @param {bitArray} iv The initialization value.  It must be 128 bits.
     * @param {bitArray} [adata=[]] The authenticated data.  Must be empty.
     * @return The encrypted data, an array of bytes.
     * @throws {sjcl.exception.invalid} if the IV isn't exactly 128 bits or if any adata is specified.
     */
    encrypt: function(prf, plaintext, iv, adata) {
        return sjcl.mode.ctr._calculate(prf, plaintext, iv, adata);
    },
    /** Decrypt in CTR mode.
     * @param {Object} prf The pseudorandom function.  It must have a block size of 16 bytes.
     * @param {bitArray} ciphertext The ciphertext data.
     * @param {bitArray} iv The initialization value.  It must be 128 bits.
     * @param {bitArray} [adata=[]] The authenticated data.  It must be empty.
     * @return The decrypted data, an array of bytes.
     * @throws {sjcl.exception.invalid} if the IV isn't exactly 128 bits or if any adata is specified.
     * @throws {sjcl.exception.corrupt} if if the message is corrupt.
     */
    decrypt: function(prf, ciphertext, iv, adata) {
        return sjcl.mode.ctr._calculate(prf, ciphertext, iv, adata);
    },
    _calculate: function(prf, data, iv, adata) {
        let l, bl, c, d, e, i;
        if (adata && adata.length) {
            throw new sjcl.exception.invalid("ctr can't authenticate data");
        }
        if (sjcl.bitArray.bitLength(iv) !== 128) {
            throw new sjcl.exception.invalid("ctr iv must be 128 bits");
        }
        if (!(l = data.length)) {
            return [];
        }
        c = iv.slice(0);
        d = data.slice(0);
        bl = sjcl.bitArray.bitLength(d);
        for (i=0; i<l; i+=4) {
            e = prf.encrypt(c);
            d[i] ^= e[0];
            d[i+1] ^= e[1];
            d[i+2] ^= e[2];
            d[i+3] ^= e[3];
            c[3]++;
        }

        return sjcl.bitArray.clamp(d, bl);
    }
};

const keySize = 256;
const ivSize = 128;
const iterations = 10000;
const blockSize = 16;

class Aes256 {

    static generateKey(password, salt) {
        const derivedKey = sjcl.misc.pbkdf2( password, salt, iterations, 2 * keySize + ivSize);

        return {
            secretKey: sjcl.bitArray.bitSlice(derivedKey, 0, keySize),
            hmacKey: sjcl.bitArray.bitSlice(derivedKey, keySize, 2 * keySize),
            iv: sjcl.bitArray.bitSlice(derivedKey, 2 * keySize, 2 * keySize + ivSize)
        }
    }

    static decrypt(encrypted, password) {
        const data = encrypted.split('\n');
        const salt = sjcl.codec.hex.toBits(data[0]);
        const encryptedHmac = sjcl.codec.hex.toBits(data[1]);
        const encryptedData = sjcl.codec.hex.toBits(data[2]);

        const key = this.generateKey(password, salt);
        const hmac = new sjcl.misc.hmac(key.hmacKey, sjcl.hash.sha256);
        if (!sjcl.bitArray.equal(hmac.encrypt(encryptedData), encryptedHmac)) {
            console.log('Invalid password');
            throw new Error('Invalid password');
        }

        const aes = new sjcl.cipher.aes(key.secretKey);
        const result = sjcl.mode.ctr.decrypt(aes, encryptedData, key.iv);

        let decryptedData = sjcl.codec.utf8String.fromBits(result);
        const pad_len = decryptedData.charCodeAt(decryptedData.length - 1);
        return pad_len > (blockSize - 1) ? decryptedData : decryptedData.substring(0, decryptedData.length - pad_len);
    }

    static encrypt(data, password) {
        const pad_len = blockSize - data.length % blockSize;
        if (pad_len !== 0) {
            for (let i = 0; i < pad_len; i++) {
                data += String.fromCharCode(pad_len);
            }
        }
        const salt = sjcl.random.randomWords(8);
        const key = this.generateKey(password, salt);
        const hmac = new sjcl.misc.hmac(key.hmacKey, sjcl.hash.sha256);
        const aes = new sjcl.cipher.aes(key.secretKey);
        const result = sjcl.mode.ctr.encrypt(aes, sjcl.codec.utf8String.toBits(data), key.iv);
        const encryptedHmac = hmac.encrypt(result);
        const encryptedData = [];
        encryptedData.push(sjcl.codec.hex.fromBits(salt));
        encryptedData.push(sjcl.codec.hex.fromBits(encryptedHmac));
        encryptedData.push(sjcl.codec.hex.fromBits(result));
        return encryptedData.join('\n')
    }
}

export default Aes256;