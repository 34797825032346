import React, { Component } from 'react';
import './App.css';
import ansible from './encryption/ansible';
import { Container, Message, Form, Segment, Popup, Label, Input, Button, Modal, Header, Icon } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import CopyToClipboard from 'react-copy-to-clipboard';

class App extends Component {
  state = {
    password: '',
    unencryptedString: '',
    encryptedString: '',
    errorMessage: '',
    modalOpen: false
  };

  checkPassword = () => {
      if (this.state.password.trim() === '') {
          this.setState({ errorMessage: 'Please enter password'});
          this.handleOpen();
          return false;
      }
      return true;
  };

  encrypt = () => {
      if (this.checkPassword()) {
          if (this.state.unencryptedString.trim() === '') {
              this.setState({ errorMessage: 'Please enter text to encrypt'});
              this.handleOpen();
              return;
          }

          this.setState({ errorMessage: '' });
          try {
              const encrypted = ansible.encrypt(this.state.unencryptedString, this.state.password);
              this.setState({ encryptedString: encrypted });
          } catch (e) {
              this.setState({ errorMessage: e.message });
              this.handleOpen();
          }
      }
  };

  decrypt = () => {
      if (this.checkPassword()) {
          this.setState({ errorMessage: '' });
          if (this.state.encryptedString.trim() === '') {
              this.setState({ errorMessage: 'Please paste encrypted text'});
              this.handleOpen();
              return;
          }

          try {
              const decrypted = ansible.decrypt(this.state.encryptedString, this.state.password);
              this.setState({ unencryptedString: decrypted });
          } catch (e) {
              this.setState({ errorMessage: e.message });
              this.handleOpen();
          }
      }
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    return (
        <Container style={{marginTop: '20px'}}>
            <Message info>
                <Message.Header>
                    Encrypt/Decrypt <code>Ansible</code> secrets
                </Message.Header>
                <Message.Content>
                    Enter the plain-text secret to the upper text box and get it encrypted in the bottom text box.
                    Or paste the secret to the bottom text box and get it decrypted in the top text box.
                </Message.Content>
            </Message>

            <Segment raised>
                <Form.Field inline>
                    <Label pointing='right'>Your Ansible Vault Password</Label>
                    <Input placeholder='Password' value={this.state.password} onChange={e => this.setState({ password: e.target.value })}/>
                </Form.Field>
            </Segment>
            <Form>
                <Label color='red' attached="top"><CopyToClipboard text={this.state.unencryptedString}><Button circular icon='copy outline' basic inverted/></CopyToClipboard>Unencrypted Text</Label>
                <TextareaAutosize
                    placeholder="Type the text here...."
                    maxRows={15}
                    minRows={5}
                    style={{paddingTop: '40px'}}
                    value={this.state.unencryptedString} onChange={e => this.setState({ unencryptedString: e.target.value })}
                />
            </Form>
            <Container style={{ padding: '1.5em' }} textAlign='center'>
                <Popup
                    trigger={<Button circular icon='arrow up' color='red' basic style={{ marginRight: '4em'}} onClick={this.decrypt}/>}
                    content='Decrypt vault content'
                    style={{
                        borderRadius: '0.4em',
                        opacity: 0.7,
                    }}
                    inverted
                    position='top right'
                />
                <Popup
                    trigger={<Button circular icon='arrow down' color='green' basic style={{ marginLeft: '4em'}} onClick={this.encrypt}/>}
                    content='Encrypt text'
                    style={{
                        borderRadius: '0.4em',
                        opacity: 0.7,
                    }}
                    inverted
                    position='top left'
                />


            </Container>
                    <Form>
                        <Label color='green' attached="top"><CopyToClipboard text={this.state.encryptedString}><Button circular icon='copy outline' basic inverted/></CopyToClipboard>Encrypted Text</Label>
                        <TextareaAutosize
                            placeholder="Copy encrypted text here...."
                            maxRows={15}
                            minRows={5}
                            style={{paddingTop: '40px'}}
                            value={this.state.encryptedString} onChange={e => this.setState({ encryptedString: e.target.value })}
                        />
                    </Form>
            <Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                basic
                size='small'
            >
                <Header icon='browser' content='Error' />
                <Modal.Content>
                    <h3>{this.state.errorMessage}</h3>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleClose} inverted>
                        <Icon name='checkmark' /> Got it
                    </Button>
                </Modal.Actions>
            </Modal>
        </Container>
    );
  }
}

export default App;
